.header {
    background-color: #000;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    top: 0;
}

.header-title {
    font-size: 2rem;
    font-weight: 600;
    width: 50%;
    text-align: left;
    cursor: pointer;
    margin-left: 2%;
}

.header-content {
    font-size: 1.5rem;
    font-weight: 400;
    width: 50%;
}

.header-content-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.header-content-button:hover {
    background-color: #fff;
    color: #000;
}

.home {
    display: grid;
    grid-template-columns: repeat(9, 200px);
    height: 99vh;
    width: 100vw;
    margin-top: 8vh;
    overflow-y: auto;
}

.selection-label-input-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.selection-label-input-div__select {
    width: 50%;
    margin: 1rem;
}

.scrollView {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
}

.FoodDetails {
    border: 2px solid gainsboro;
    border-radius: 10px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 70%
}

.FoodDetails__Name {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
    text-align: center;
    text-decoration: underline;
}

.FoodDetails__ImportantInfor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FoodDetails__TextImportant, .FoodDetails__TextImportantFacts {
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
}

.FoodDetails__TextImportantFacts {
    text-align: center;
}


.FoodDetails__NutritionFacts {
    max-height: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 15px;
    overflow: auto;
}

.FoodDetails_Button {
    text-align: center;
    color: white;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: dodgerblue;
    padding: 10px;
    border-radius: 10px;
}

.OverWriteFormContainer {
    margin: none;
    margin-top: 20%;
}